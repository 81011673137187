.zoom {
    padding: 10px;
    /* background-color: green; */
    transition: transform .2s;
    /* width: 200px; */
    height: 200px;
    margin: 0 auto;
  }
  
  .zoom:hover {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Safari 3-8 */
    transform: scale(1.5); 
  }
  .imgg{
      height: -webkit-fill-available;
      /* height: 206px; */
  }
  .imggg{
    height: -webkit-fill-available;
    margin-top: -5%;
}
  .relationHeadProducts{
    color:#5497da;
    text-align: center;
    font-weight: bold;
    letter-spacing: 0.5px;
    font-size: 30px;
    font-family: "Montserrat", Arial, Tahoma, sans-serif;
}
.product1Info{
  /* margin-left: 44%; */
  width: inherit;
  text-align: justify;
  font-family: "Montserrat", Arial, Tahoma, sans-serif;
}
.product2Info{
  /* margin-left: 44%; */
  width:inherit;
  text-align: justify;
  font-family: "Montserrat", Arial, Tahoma, sans-serif;
}