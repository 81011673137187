.hover{
    height: 17px;
    margin-left: 5%;
    
}
.footer{
    border-top: grey;
    margin-left: 5%;
}
.footerPart2{
    /* float: right; */
    /* margin-top: -142%; */
    /* margin-left: 70%; */
    font-family: "Montserrat", Arial, Tahoma, sans-serif;
}
.mailCheck{
    color: #5497da;
    font-size: 28px;
    text-decoration: none;
    font-family: "Montserrat", Arial, Tahoma, sans-serif;
}

.addressSilverback{
    margin-left: 0%;
    color: #555;
    width: max-content;
  }
  .phone{
    width: max-content;
  }


 .footer_copy {
    border-top: 1px solid rgba(106, 58, 188, 0.19);
    height: 90px;
  }

  .vl {
    border-left: 2px solid rgba(106, 58, 188, 0.19);
    height:243px;
    margin-top: 10px;
    margin-left: 54%;
  }

  .copyright{
    padding: 25px;
    font-size: 18px;
    color: #555;
    text-align: center;
    font-family: "Montserrat", Arial, Tahoma, sans-serif;

  }
  .achievementSection{
    width: 110%;
    margin-top: 20%;
    height: 200px;
  }
  .achievementSection1{
    width: 110%;
    margin-top: 12%;
    height: 193px;
    
  }
  .igniteHead{
    width: 100%;
    text-align: center;
    color: #5497da;
    font-size: 17px;

  }