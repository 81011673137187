.headerSlider{
    font-size: 30px;
    color:#5497da;
    text-align: center;
    line-height: 80px;
    font-weight: 400;
    letter-spacing: 0px;
    font-family: "Montserrat", Arial, Tahoma, sans-serif;
}
#textSlider{
    text-align: center;
    color:#555;
    font: inherit;
    line-height: 30px;
    letter-spacing: 0.5px;
}
.service1{
    /* border:1px solid lightgray; */
    box-shadow:0px 1px 3px 0px #555;
    /* width:40%; */
    /* height: 570px; */
    /* 
    margin-right:2%;
    margin-left: 5%;
    padding-top: 35px; */
    /* box-shadow: 10px 15px 10px -15px red; */
    padding: 20px;
   

}
.service2{
    /* width:40%; */
    /* border:1px solid lightgray; */
    box-shadow:0px 1px 3px 0px #555;
    /* margin-right: 2%;
    padding-top: 35px; */
    padding: 20px;
    /* height: 531px; */
}
.service3{
    /* width:40%; */
    /* border:1px solid lightgray;  */
    box-shadow: 0px 1px 3px 0px #555;
    /* margin-right: 5%;
    padding-top: 35px;*/
    padding: 20px; 
    /* height: 531px; */

}
.servicesImages{
    /* padding-top:30px; */
    height: 90px;
    max-width: 100%;
    margin-left: 35%;
    fill:red;
}
.servicesHeaders{
    color: #5497da;
    font-size: 27px;
    text-align: center;
    margin-bottom: 25px;
    font-weight: lighter; 
   
}
.servicesParas{
    color:#555;
    text-align: left ;
    line-height: 25px;
    letter-spacing: 0.5px;
    font-family: "Montserrat", Arial, Tahoma, sans-serif;
}
.relationHead{
    color:#5497da;
    text-align: center;
    font-weight: lighter;
    letter-spacing: 1.5px;
    font-size: 35px;
}
.relationPara{
    color:#555;
    text-align: center;
    letter-spacing: -.5px;
    line-height: 62px;
    font-size: 17px;
    font-family: "Montserrat", Arial, Tahoma, sans-serif;
}
.employeesSection{
    padding: 5%;
    height: 250px;
}
.desc{
    width: 73%;
    float: left;
    margin: 6% 7% 20px;
}
.employeeName{
    font-size: 23px;
}
.subtitle{
    font-size: 16px;
    line-height: 36px;
    color: #555;
}
.blockquote{
    color: #444444;
    line-height: 30px;
    font-size: 18px;
    font-family: "Montserrat", Arial, Tahoma, sans-serif;
}
.servicesList{
    font-size: 15px;
    margin-left: auto;
    padding-left: 18px;
}
.serviceData{
    font-size: 16px;
    text-align: justify;
}
.relationHeadServices{
    color:#5497da;
    text-align: center;
    font-weight: bold;
    letter-spacing: 0.5px;
    font-size: 30px;
    font-family: "Montserrat", Arial, Tahoma, sans-serif;

}
.tada{
    fill: gold;
}