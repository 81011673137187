/* .about{
    text-align: center;
    font-size: 2em;
    color:#555
}
.aboutPara{
    text-align: center;
    font-size: 15px;
} */
.headerSliderAbout{
    font-size: 30px;
    color:#5497da;
    text-align: center;
    line-height: 80px;
    font-weight: bold;
    letter-spacing: 0.5px;
    font-family: "Montserrat", Arial, Tahoma, sans-serif;
}
#textSliderAbout{
    text-align: center;
    color:#555;
    font: inherit;
    line-height: 30px;
    letter-spacing: 0.5px;
    font-size: 17px;
    width: 101%;
    margin-left: 17px;
    padding-left: 0%;
    font-family: "Montserrat", Arial, Tahoma, sans-serif;
}
.ml6 {
    position: relative;
    font-weight: 900;
    font-size: 3.3em;
  }
  
  .ml6 .text-wrapper {
    position: relative;
    display: inline-block;
    padding-top: 0.2em;
    padding-right: 0.05em;
    padding-bottom: 0.1em;
    overflow: hidden;
  }
  
  .ml6 .letter {
    display: inline-block;
    line-height: 1em;
  }