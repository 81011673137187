* {
    box-sizing: border-box;
  }
.column1 {
    float: left;
    width: 60%;
    padding: 65px;
    height: 480px; /* Should be removed. Only for demonstration */
  }
  .column2 {
    float: left;
    width: 40%;
    padding: 65px;
    height: 480px; /* Should be removed. Only for demonstration */
  }

  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  .vc_column-inner{
    font-family: "Montserrat", Arial, Tahoma, sans-serif;
    
  }
  .techMaterial{
      letter-spacing: 0.5px;
      line-height: 27px;
      text-align: justify;
  }
  
  
  .techHeader{
    text-align: left;
    line-height: 40px;
    font-size: 30px;
    font-weight: bold;
  }
  .techSkill{
    margin-left: 5%;
  }
  .techStrategy{
    margin-top: 3%;
  }