*{
    margin:0;
    padding: 0;
}

body{
    background-color: #fff;
}
.pages{
    /* color:#316685;
    text-align: center;
    font-size: 3.5rem;
    margin-top: 10%; */
}