body{
    font-family: 'Dosis', sans-serif;
}
.navbar{
    background-color: #fff;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    /* position: sticky; */
    top:0;
    z-index: 20;
    width: 100%;
    border-bottom: 1px solid #5497da;
}
.nav-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    max-width: 1500px;
}
.nav-logo{
    color:#5497da;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    flex-grow: 1;
    margin-top: -40px;
}
.nav-menu{
    margin-top: -1px;
    display:flex;
    list-style: none;
    text-align: center;
    margin-right: 2rem;
}
.nav-links{
    color: #555;
    /* text-decoration: none; */
    padding: 0.5rem 1rem;
    height: 100%;
    border: 3px solid transparent;
}
.nav-item{
    line-height: 40px;
    margin-right: 1rem;
    font-size: 17px;
    text-decoration: none;

}
.nav-item:after{
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;    
    transition: width 0.7s ease, background-color 0.5s ease;
}
.nav-item:hover:after{
    width: 100%;
    background:#5497da ;
}
.nav-item .active{
    color:#5497da;
    /* border: 1px solid #ffdd40; */
}

.nav-icon{
    display: none;
}

@media screen and (max-width: 960px){
    .nav-menu{
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1px solid #fff;
        position: absolute;
        top:80px;
        left:-110%;
        opacity: 1;
    }
    .nav-menu.active{
        background: #fff;
        left: 0px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }
    .nav-links{
        padding: 1.5rem;
        width: 100%;
        display: table;
        text-decoration: none;
    }
    .nav-icon{
        display: block;
        position: absolute;
        top:0;
        right: 0;
        transform: translate(-100%,60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #5497da;
    }
}